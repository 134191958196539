<template>
  <div class="item-features">
    <div class="row text-center">
      <div class="col-12 col-md-4 mb-4 mb-md-0">
        <div class="w-75 mx-auto">
          <feather-icon
            icon="PlusCircleIcon"
            size="35"
            badge=1
          />
          <h4 class="mt-2 mb-1">
            Create Project
          </h4>
          <p class="card-text">
            Simply create a new project and provide App name, Website Url, App logo and App colors
          </p>
        </div>
      </div>
      <div class="col-12 col-md-4 mb-4 mb-md-0">
        <div class="w-75 mx-auto">
          <feather-icon
            icon="SettingsIcon"
            size="35"
            badge=2
          />
          <h4 class="mt-2 mb-1">
            Customize
          </h4>
          <p class="card-text">
            Upload Splash screen and setup features, onboarding intro screens. Enable Notifications and Admob
          </p>
        </div>
      </div>
      <div class="col-12 col-md-4 mb-4 mb-md-0">
        <div class="w-75 mx-auto">
          <feather-icon
            icon="DownloadCloudIcon"
            size="35"
            badge=3
          />
          <h4 class="mt-2 mb-1">
            Download APK/AAB
          </h4>
          <p class="card-text">
            Click on build, Configure Signing options, get publishable App bundle and debug APK within few minutes.
          </p>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {

}
</script>

<style>

</style>
