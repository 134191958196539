<template>
  <div class="w-100">
    <section id="knowledge-base-search">
      <b-card
        no-body
        class="knowledge-base-bg text-center"
        :style="{backgroundImage: `url(${require('@/assets/images/banner/banner.png')})`}"
      >
        <b-card-body class="card-body">
          <h2 class="text-primary">
            Convert your website into an Android App.
          </h2>
          <b-card-text class="mb-2">
            <!-- <span>Popular searches: </span> -->
            <span class="font-weight-bolder">Convert any website into a native Android app with Splash screen and Notifications/Admob enabled</span>
          </b-card-text>
          <iframe class="text-center" width="75%" height="365" src="https://www.youtube.com/embed/PhXy3X7zHYs" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowfullscreen></iframe>
          <br/>
          <br/>
          <b-button
              class="text-center"
              v-ripple.400="'rgba(255, 255, 255, 0.15)'"
              size="lg"
              variant="primary"
              @click="onStartClick()"
          >
            Start Converting
          </b-button>
        </b-card-body>
      </b-card>
    </section>
    <section id="knowledge-base-content">
      <!-- content -->
      <b-row class="kb-search-content-info match-height">
        <b-col
          v-for="item in knowledgeBase"
          :key="item.id"
          md="4"
          sm="6"
          class="kb-search-content"
        >
          <b-card
            class="text-center cursor-pointer"
            :img-src="item.img"
            :img-alt="item.img.slice(5)"
            img-top
          >
            <h4>{{ item.title }}</h4>
            <b-card-text class="mt-1">
              {{ item.desc }}
            </b-card-text>
          </b-card>
        </b-col>
      </b-row>
    </section>

    <section id="steps-content">
      <!-- content -->
      <!-- title text and description -->
      <div class="text-center">
        <h1 class="mt-5">
          How It Works
        </h1>
        <p class="mb-1">
          Our system is Pretty Simple and Clear. Very easy to use. Converting website into Android app has never been easy this much.
        </p>
        <p class="mb-2 pb-75">
          Our 3 step process will bring you amazing Android app with no extra effort. Creating App takes only few clicks.
        </p>
      </div>
      <!--/ title text and description -->
      <home-steps />
    </section>

    <!-- download trial app -->
    <div class="pricing-free-trial">
      <b-row >
        <b-col
          lg="10"
          offset-lg="3"
          class="mx-auto"
        >
          <div class="pricing-trial-content d-flex justify-content-between">
            <div class="text-center text-md-left mt-3 mb-5">
              <h3 class="text-primary">
                Download The App Made with WebToAppConvert
              </h3>
              <h5>Download WebToAppConvert built using our own system to witness the magic..</h5>
              <b-button
                v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                variant="relief-primary"
                class="mt-2 mt-lg-3 mb-5"
                @click="downloadApp()"
              >
                <feather-icon
                  icon="DownloadIcon"
                  class="mr-50"
                />
                Download App
              </b-button>
            </div>

            <!-- images -->
            <b-img
              fluid
              :src="require('@/assets/images/app-related/app-demo-image.png')"
              class="app-trial-img"
              alt="svg img"
            />
            <!--/ images -->
          </div>
        </b-col>
      </b-row>
    </div>
    <!--/ download trial app-->
    <section
      v-if="Object.keys(pricing).length"
      id="pricing-plan"
    >
      <!-- title text and switch button -->
      <div class="text-center">
        <h1 class="mt-5">
          Pricing Plans
        </h1>
        <p class="mb-2 pb-75">
          All plans include personal support and all build failures will be recovered. Choose the best plan to fit your needs.
        </p>
        <div class="d-flex align-items-center justify-content-center mb-5 pb-50">
          <h6 class="mr-1 mb-0">
            Per Build
          </h6>
          <b-form-checkbox
            id="priceSwitch"
            v-model="status"
            name="price-switch"
            value="monthly"
            unchecked-value="build"
            switch
            @input="tooglePlan"
          />
          <h6 class="ml-50 mb-0">
            Monthly
          </h6>
        </div>
      </div>
      <!--/ title text and switch button -->

      <!-- pricing plan cards -->
      <b-row class="pricing-card">
        <b-col
          offset-sm-2
          sm="10"
          md="12"
          offset-lg="2"
          lg="10"
          class="mx-auto"
        >
          <b-row>
            <b-col md="4">
              <b-card
                align="center"
              >
                <!-- img -->
                <b-img
                  v-if="pricing.basicPlan.img"
                  :src="pricing.basicPlan.img"
                  class="mb-2 mt-5"
                  alt="basic svg img"
                />
                <!--/ img -->
                <h3>{{ pricing.basicPlan.title }}</h3>
                <b-card-text>
                  {{ pricing.basicPlan.subtitle }}
                </b-card-text>

                <!-- annual plan -->
                <div class="annual-plan">
                  <div class="plan-price mt-2">
                    <sup class="font-medium-1 font-weight-bold text-primary">$</sup>
                    <span class="pricing-basic-value font-weight-bolder text-primary">{{ !monthlyPlanShow ? pricing.basicPlan.buildPrice:pricing.basicPlan.monthlyPlan.perMonth }}</span>
                    <sub class="pricing-duration text-body font-medium-1 font-weight-bold">/{{ monthlyPlanShow ? 'month' : '3 builds' }}</sub>
                  </div>
                  <small
                    v-show="monthlyPlanShow"
                    class="annual-pricing text-muted"
                  >Unlimited builds</small>
                </div>
                <!--/ annual plan -->

                <!-- subscription plan benefit -->
                <b-list-group
                  v-if="monthlyPlanShow"
                  class="list-group-circle text-left"
                >
                  <b-list-group-item
                    v-for="(data,index) in pricing.basicPlan.subscriptionPlanBenefits"
                    :key="index"
                  >
                    {{ data }}
                  </b-list-group-item>
                </b-list-group>
                <!--/ subscription plan benefit -->

                <!-- plan benefit -->
                <b-list-group class="list-group-circle text-left">
                  <b-list-group-item
                    v-for="(data,index) in pricing.basicPlan.planBenefits"
                    :key="index"
                  >
                    <del v-if="!data.available">{{ data.message }}</del>
                    <div v-else>
                      {{ data.message }}
                    </div>
                  </b-list-group-item>
                </b-list-group>
                <!--/ plan benefit -->

                <!-- buttons -->
                <b-button
                  v-ripple.400="'rgba(40, 199, 111, 0.15)'"
                  block
                  class="mt-2"
                  variant="outline-success"
                  @click="buyProduct('basic')"
                >
                  <div v-if="!monthlyPlanShow">
                    Buy Builds
                  </div>
                  <div v-if="monthlyPlanShow">
                    Subscribe
                  </div>
                </b-button>
              </b-card>
            </b-col>
            <b-col md="4">
              <b-card
                class="popular"
                align="center"
              >
                <div
                  v-show="pricing.standardPlan.popular"
                  class="pricing-badge text-right"
                >
                  <b-badge
                    variant="light-primary"
                    pill
                  >
                    Popular
                  </b-badge>
                </div>
                <!-- img -->
                <b-img
                  v-if="pricing.standardPlan.img"
                  :src="pricing.standardPlan.img"
                  class="mb-1"
                  alt="svg img"
                />
                <!--/ img -->
                <h3>{{ pricing.standardPlan.title }}</h3>
                <b-card-text>{{ pricing.standardPlan.subtitle }}</b-card-text>

                <!-- annual plan -->
                <div class="annual-plan">
                  <div class="plan-price mt-2">
                    <sup class="font-medium-1 font-weight-bold text-primary">$</sup>
                    <span class="pricing-basic-value font-weight-bolder text-primary">{{ !monthlyPlanShow ? pricing.standardPlan.buildPrice:pricing.standardPlan.monthlyPlan.perMonth }}</span>
                    <sub class="pricing-duration text-body font-medium-1 font-weight-bold">/{{ monthlyPlanShow ? 'month' : '3 builds' }}</sub>
                  </div>
                  <small
                    v-show="monthlyPlanShow"
                    class="annual-pricing text-muted"
                  >Unlimited builds</small>
                </div>
                <!--/ annual plan -->

                <!-- subscription plan benefit -->
                <b-list-group
                  v-if="monthlyPlanShow"
                  class="list-group-circle text-left"
                >
                  <b-list-group-item
                    v-for="(data,index) in pricing.standardPlan.subscriptionPlanBenefits"
                    :key="index"
                  >
                    {{ data }}
                  </b-list-group-item>
                </b-list-group>
                <!--/ subscription plan benefit -->

                <!-- plan benefit -->
                <b-list-group class="list-group-circle text-left">
                  <b-list-group-item
                    v-for="(data,index) in pricing.standardPlan.planBenefits"
                    :key="index"
                  >
                    <del v-if="!data.available">{{ data.message }}</del>
                    <div v-else>
                      {{ data.message }}
                    </div>
                  </b-list-group-item>
                </b-list-group>
                <!--/ plan benefit -->

                <!-- buttons -->
                <b-button
                  v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                  block
                  class="mt-2"
                  variant="primary"
                  @click="buyProduct('standard')"
                >
                  <div v-if="!monthlyPlanShow">
                    Buy Builds
                  </div>
                  <div v-if="monthlyPlanShow">
                    Subscribe
                  </div>
                </b-button>
              </b-card>
            </b-col>
            <b-col md="4">
              <b-card
                align="center"
              >
                <!-- img -->
                <b-img
                  v-if="pricing.enterprisePlan.img"
                  :src="pricing.enterprisePlan.img"
                  class="mb-2"
                  alt="enterprise svg img"
                />
                <!--/ img -->
                <h3>{{ pricing.enterprisePlan.title }}</h3>
                <b-card-text>{{ pricing.enterprisePlan.subtitle }}</b-card-text>

                <!-- annual plan -->
                <div class="annual-plan">
                  <div class="plan-price mt-2">
                    <sup class="font-medium-1 font-weight-bold text-primary">$</sup>
                    <span class="pricing-basic-value font-weight-bolder text-primary">{{ !monthlyPlanShow ? pricing.enterprisePlan.buildPrice:pricing.enterprisePlan.monthlyPlan.perMonth }}</span>
                    <sub class="pricing-duration text-body font-medium-1 font-weight-bold">/{{ monthlyPlanShow ? 'month' : '3 builds' }}</sub>
                  </div>
                  <small
                    v-show="monthlyPlanShow"
                    class="annual-pricing text-muted"
                  >Unlimited builds</small>
                </div>
                <!--/ annual plan -->

                <!-- subscription plan benefit -->
                <b-list-group
                  v-if="monthlyPlanShow"
                  class="list-group-circle text-left"
                >
                  <b-list-group-item
                    v-for="(data,index) in pricing.enterprisePlan.subscriptionPlanBenefits"
                    :key="index"
                  >
                    {{ data }}
                  </b-list-group-item>
                </b-list-group>
                <!--/ subscription plan benefit -->

                <!-- plan benefit -->
                <b-list-group
                  v-for="(data,index) in pricing.enterprisePlan.planBenefits"
                  :key="index"
                  class="list-group-circle text-left"
                >
                  <b-list-group-item>
                    <del v-if="!data.available">{{ data.message }}</del>
                    <div v-else>
                      {{ data.message }}
                    </div>
                  </b-list-group-item>
                </b-list-group>
                <!--/ plan benefit -->

                <!-- buttons -->
                <b-button
                  v-ripple.400="'rgba(113, 102, 240, 0.15)'"
                  block
                  class="mt-2"
                  variant="outline-primary"
                  @click="buyProduct('premium')"
                >
                  <div v-if="!monthlyPlanShow">
                    Buy Builds
                  </div>
                  <div v-if="monthlyPlanShow">
                    Subscribe
                  </div>
                </b-button>
              </b-card>
            </b-col>
          </b-row>
        </b-col>
      </b-row>
      <!--/ pricing plan cards -->

      <!-- pricing free trial -->
      <div class="pricing-free-trial">
        <b-row>
          <b-col
            lg="10"
            offset-lg="3"
            class="mx-auto"
          >
            <div class="pricing-trial-content d-flex justify-content-between">
              <div class="text-center text-md-left mt-3">
                <h3 class="text-primary">
                  Still not convinced? Start with 3 FREE builds!
                </h3>
                <h5>You will get 3 basic builds to try before buying anything.</h5>
                <b-button
                  v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                  variant="primary"
                  class="mt-2 mt-lg-3"
                  @click="getFreeBuilds()"
                >
                  Get Free Builds
                </b-button>
              </div>

              <!-- images -->
              <b-img
                fluid
                :src="require('@/assets/images/illustration/pricing-Illustration.svg')"
                class="pricing-trial-img"
                alt="svg img"
              />
            <!--/ images -->
            </div>
          </b-col>
        </b-row>
      </div>
      <!--/ pricing free trial -->

      <!-- pricing faq -->
      <div class="pricing-faq">
        <h3 class="text-center">
          FAQ's
        </h3>
        <p class="text-center">
          Let us help answer the most common questions.
        </p>
        <b-row class="py-2">
          <b-col
            lg="10"
            offset-lg="2"
            class="mx-auto"
          >
            <app-collapse
              accordion
              type="margin"
            >

              <app-collapse-item
                v-for="(data,index) in pricing.qandA"
                :key="index"
                :title="data.question"
              >
                {{ data.ans }}
              </app-collapse-item>
            </app-collapse>
          </b-col>
        </b-row>
      </div>
    <!--/ pricing faq -->
    </section>
  </div>
</template>

<script>
import {
  BFormCheckbox, BRow, BCol, BCard, BImg, BCardText, BListGroup, BListGroupItem, BButton, BBadge, BCardBody,
} from 'bootstrap-vue'
import AppCollapse from '@core/components/app-collapse/AppCollapse.vue'
import AppCollapseItem from '@core/components/app-collapse/AppCollapseItem.vue'
import Ripple from 'vue-ripple-directive'
import HomeSteps from './pages/home/HomeSteps.vue'
/* eslint-disable global-require */
export default {
  components: {
    BFormCheckbox,
    BButton,
    BCardText,
    BListGroup,
    BListGroupItem,
    BRow,
    BCol,
    BCard,
    BBadge,
    BImg,
    BCardBody,
    AppCollapseItem,
    AppCollapse,

    HomeSteps,
  },
  directives: {
    Ripple,
  },
  data() {
    return {
      status: 'build',
      monthlyPlanShow: false,
      pricing: {
        basicPlan: {
          img: 'https://webtoappconvert.web.app/img/Pot1.svg',
          title: 'Basic',
          subtitle: 'A simple start for everyone',
          buildPrice: '4.99',
          monthlyPlan: {
            perMonth: '29.99',
            totalBuild: '100',
          },
          planBenefits: [
            { message: 'App Icon', available: true },
            { message: 'Splash Screen', available: true },
            { message: 'WebToAppConvert.com branding', available: true },
            { message: 'Pull to refresh', available: true },
            { message: 'Onboarding screens', available: false },
            { message: 'Push Notifications', available: false },
            { message: 'AdMob support', available: false },
            { message: 'File download', available: false },
            { message: 'File upload', available: false },
            { message: 'Enable caching', available: false },
            { message: 'Support deep linking', available: false },
            { message: 'Preserve cookies', available: false },
            { message: 'APK/AAB file', available: true },
          ],
          subscriptionPlanBenefits: ['Unlimited Basic builds'],
        },
        standardPlan: {
          popular: true,
          img: 'https://webtoappconvert.web.app/img/Pot2.svg',
          title: 'Standard',
          subtitle: 'Pack of most essential features',
          buildPrice: '9.99',
          monthlyPlan: {
            perMonth: '99.99',
            totalBuild: '100',
          },
          planBenefits: [
            { message: 'App Icon', available: true },
            { message: 'Splash Screen', available: true },
            { message: 'WebToAppConvert.com branding', available: false },
            { message: 'Pull to refresh', available: true },
            { message: 'Onboarding screens', available: true },
            { message: 'Push Notifications', available: true },
            { message: 'AdMob support', available: false },
            { message: 'File download', available: true },
            { message: 'File upload', available: false },
            { message: 'Enable caching', available: true },
            { message: 'Support deep linking', available: false },
            { message: 'Preserve cookies', available: false },
            { message: 'APK/AAB file', available: true },
          ],
          subscriptionPlanBenefits: ['Unlimited Basic builds', 'Unlimited Standard builds'],
        },
        enterprisePlan: {
          popular: true,
          img: 'https://webtoappconvert.web.app/img/Pot3.svg',
          title: 'Premium',
          subtitle: 'With all the features you can imagine',
          buildPrice: '14.99',
          monthlyPlan: {
            perMonth: '149.99',
            totalBuild: '100',
          },
          planBenefits: [
            { message: 'App Icon', available: true },
            { message: 'Splash Screen', available: true },
            { message: 'WebToAppConvert.com branding', available: false },
            { message: 'Pull to refresh', available: true },
            { message: 'Onboarding screens', available: true },
            { message: 'Push Notifications', available: true },
            { message: 'AdMob support', available: true },
            { message: 'File download', available: true },
            { message: 'File upload', available: true },
            { message: 'Enable caching', available: true },
            { message: 'Support deep linking', available: true },
            { message: 'Preserve cookies', available: true },
            { message: 'APK/AAB file', available: true },
          ],
          subscriptionPlanBenefits: ['Unlimited Basic builds', 'Unlimited Standard builds', 'Unlimited Premium builds'],
        },
        qandA: [
          {
            question: 'What is a native Android APP?',
            ans:
                'A native application (native app) is an application program that has been developed for use on a particular platform or device. A native Android app is a smartphone application that is coded in a Java to run on Android devices.',
          },
          {
            question: 'What is an APK file?',
            ans:
                'APK (Android Package) is the package file format used by the Android operating system for distribution and installation of mobile apps. You can upload APK file to Google PlayStore. Also there is a new file format called App Bundles (ABB) as well.',
          },
          {
            question: 'How much will this actually cost?',
            ans:
                'You can convert your website to an APP for free. And also you can use or distribute the free App as you want. You can customize your APP by buying 4.99$ package and you can enable ads and earn money with 9.99$ package.',
          },
          {
            question: 'What is splash screen?',
            ans:
                'Splash Screen is the first screen visible to the user when the application is launched. Splash screens are used to display the application logo or any illustrations.',
          },
          {
            question: 'What is Push notifications?',
            ans:
                'Push notifications are small messages that can reach audiences anywhere and anytime. You can send notifications to APP users via OneSignal dashboard or using our plugin for Wordpress. With push notifications you can engage with your app users easily',
          },
          {
            question: 'What is Admob?',
            ans:
                'AdMob is a mobile advertising service provided by Google. You can setup Admob account with your APP and earn money.',
          },
          {
            question: 'Will this work with any website?',
            ans:
                'Yes! You can convert any website. If you are not sure try it for free and buy later.',
          },
          {
            question: 'Will this work with Wordpress?',
            ans:
                'Yes! WebToAppConvert is specially optimized for Wordpress websites. You can also contact us to get an advanced App for your WordPress site.',
          },
          {
            question: 'What are the available payment options?',
            ans:
                'You can pay with your Credit/Debit card or PayPal balance, Also you will be able to Pay with Apple Pay and Google Pay if you select Stripe as the payment gateway in checkout page',
          },
        ],

      },
      knowledgeBase: [
        {
          id: 3,
          category: 'Fast Performance',
          img: require('@/assets/images/illustration/api.svg'),
          title: 'Fast Performance',
          desc: 'Native Android App brings you the best Performance. Optimized for maximum speed loading.',
        },
        {
          id: 4,
          category: 'Easily Customizable',
          img: require('@/assets/images/illustration/personalization.svg'),
          title: 'Easily Customizable',
          desc: 'Total control in your hand. Change Icon, Splash screen, Colors and everything you can imagine.',
        },
        {
          id: 6,
          category: 'Push Notification/Admob Support',
          img: require('@/assets/images/illustration/demand.svg'),
          title: 'Push Notification/Admob Support',
          desc: 'Enable OneSignal Notifications to engage with users, and Admob ads to monetize your App and earn money.',
        },
      ],
    }
  },
  created() {
    if (this.$route.query.plan === 'monthly') {
      this.status = 'monthly'
      this.tooglePlan()
    }
  },
  methods: {
    onStartClick() {
      this.$router.push('/dashboard')
    },
    onLoginClick() {
      this.$router.push('/login')
    },
    tooglePlan() {
      if (this.status === 'monthly') {
        this.monthlyPlanShow = true
      } else {
        this.monthlyPlanShow = false
      }
    },
    buyProduct(product) {
      let item = product
      const sellingProduct = {
        title: '',
        subtitle: '',
        price: '0.0',
        qty: 1,
      }
      if (this.monthlyPlanShow) { item = `${product}_subscription` }
      if (item === 'basic') {
        sellingProduct.title = this.pricing.basicPlan.title
        sellingProduct.subtitle = this.pricing.basicPlan.subtitle
        sellingProduct.price = this.pricing.basicPlan.buildPrice
      } else if (item === 'basic_subscription') {
        sellingProduct.title = `${this.pricing.basicPlan.title} Subscription`
        sellingProduct.subtitle = this.pricing.basicPlan.subtitle
        sellingProduct.price = this.pricing.basicPlan.monthlyPlan.perMonth
      } else if (item === 'standard') {
        sellingProduct.title = this.pricing.standardPlan.title
        sellingProduct.subtitle = this.pricing.standardPlan.subtitle
        sellingProduct.price = this.pricing.standardPlan.buildPrice
      } else if (item === 'standard_subscription') {
        sellingProduct.title = `${this.pricing.standardPlan.title} Subscription`
        sellingProduct.subtitle = this.pricing.standardPlan.subtitle
        sellingProduct.price = this.pricing.standardPlan.monthlyPlan.perMonth
      } else if (item === 'premium') {
        sellingProduct.title = this.pricing.enterprisePlan.title
        sellingProduct.subtitle = this.pricing.enterprisePlan.subtitle
        sellingProduct.price = this.pricing.enterprisePlan.buildPrice
      } else if (item === 'premium_subscription') {
        sellingProduct.title = `${this.pricing.enterprisePlan.title} Subscription`
        sellingProduct.subtitle = this.pricing.enterprisePlan.subtitle
        sellingProduct.price = this.pricing.enterprisePlan.monthlyPlan.perMonth
      }
      this.$store.dispatch('checkout/setSellingProduct', sellingProduct)
      this.$router.push({ name: 'checkout' })
    },
    getFreeBuilds() {
      this.$router.push({ name: 'register' })
    },
    downloadApp() {
      window.open('https://play.google.com/store/apps/details?id=com.webtoappconvert.app', '_blank')
    },
  },
  metaInfo: {
    // Overwrite the SEO title.
    title: 'Home',
    meta: [
      { name: 'description', content: 'Convert any website to Android Application for free.' },
    ],
  },
}
/* eslint-disable global-require */
</script>

<style lang="scss">
@import '@core/scss/vue/pages/page-pricing.scss';
@import '@core/scss/vue/pages/page-knowledge-base.scss';
.app-trial-img {
  top: 0;
  margin-top: -3rem;
  height: 450px;
}
</style>
